import React, { useContext, useState } from "react";
import "./style.scss";
import { userContext } from "../../context/User";
import HazoAi from "../../assets/img/hazo_ai.png";
import HazoLettering from "../../assets/img/Picture5.png";
import Metrics from "../../components/Metrics";
import Insights from "../../components/Insights";
import UserService from "../../services/authService";

const Dashboard = () => {
  const { user, project, userName } = useContext(userContext);
  const [menuOpen, setMenuOpen] = useState(true);
  const menuList = ["Hazo Metrics", "Hazo Insights"];
  const [listSelected, setListSelected] = useState("Hazo Metrics");

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (project && user && userName) {
    return (
      <div className="compDashboard">
        <div className={`sidebar ${menuOpen ? "menu-open" : "menu-closed"}`}>
          <button className="menuToggle" onClick={toggleMenu}>
            ☰
          </button>
          {menuOpen && (
            <>
              <img alt="Hazo avatar" className="lettering" src={HazoAi} />
              <img
                alt="Hazo AI Lettering"
                className="lettering"
                src={HazoLettering}
              />
              <ul className="menuOptions">
                {menuList.map((month, index) => (
                  <li
                    className={listSelected === month ? "selected" : ""}
                    key={index}
                    onClick={() => setListSelected(month)}
                  >
                    {month}
                  </li>
                ))}
                <li onClick={() => UserService.signOut()}>Logout</li>
              </ul>
            </>
          )}
        </div>
        <div
          className={`${
            menuOpen ? "dashboardMenuOpen" : "dashboardMenuClosed"
          }`}
        >
          {listSelected === "Hazo Metrics" && <Metrics />}
          {listSelected === "Hazo Insights" && <Insights />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Dashboard;
