import React from "react";
import {Oval} from "react-loader-spinner";

import "./styles.scss";

const Loading = () => {
  return (
    <div className="container-loader">
      <Oval
        height={80}
        width={80}
        color="#609ef1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#609ef1"
        strokeWidth={2}
        strokeWidthSecondary={2}

      />
    </div>
  );
};

export default Loading;
