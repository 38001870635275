import { createContext, useEffect, useState } from "react";
import GeneralService from "../../services/generalService";

export const userContext = createContext({});

export default function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [project, setProject] = useState(null);
  const [userName, setUserName] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  useEffect(() => {
    const handleProject = async () => {
      const response = await GeneralService.getProject();
      if (Array.isArray(response.data)) {
        setProject(response.data[0].project);
      }
    };

    if (project === null && user) {
      handleProject();
    }

    const handleName = async () => {
      const response = await GeneralService.getUserProfile();

      if (Array.isArray(response.data)) {
        let tempCompanyName = response.data.filter(
          (element) => element.type_description === "company_name"
        );
        let tempUserName = response.data.filter(
          (element) => element.type_description === "name"
        );

        if (tempCompanyName) {
          setCompanyName(tempCompanyName[0].description);
        } else {
          setCompanyName("Empresa");
        }

        if (tempUserName) {
          setUserName(tempUserName[0].description);
        } else {
          setUserName("Usuario");
        }
      }
    };

    if (userName === null && user) {
      handleName();
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <userContext.Provider
      value={{
        companyName,
        project,
        setUser,
        user,
        userName
      }}
    >
      {children}
    </userContext.Provider>
  );
}
