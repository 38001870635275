import BoxChart from "../MetricsCharts/BoxChart";
import BarChart from "../MetricsCharts/BarChart";
import "./style.scss";
import { useContext, useEffect, useState } from "react";
import Crossing from "../MetricsCharts/Crossing";
import {
  boxList,
  objBuyScore,
  objBrandRelationship,
  objBrandRelationshipBrandEvangelism,
  objBrandRelationshipWordOfMouth,
  objBrandRelationshipBrandLove,
  objBrandRelationshipBrandExperienceQuality,
  objBrandRelationshipBrandExperienceBenefitCost,
  objConsumerBehavior,
  objCustomerExperience,
  objMarketSuperiority,
  objElasticity,
  objEmployeeBranding,
  objInovation,
  objMarketFit
} from "./constants";
import {
  requestSimpleDataWithClassification,
  requestSimpleDataWithoutClassification,
  requestSimpleFrequencyBrandRelationship,
  requestSimpleFrequencyPsy,
  requestAndSetNpsCalculatedData,
  requestAndSetNpsSimpleFrequencyData,
  requestAndSetNpsCrossingData
} from "./functions";
import { userContext } from "../../context/User";

function Metrics() {
  const [brandRelationship, setBrandRelationship] = useState(null);
  const [
    brandRelationshipBrandEvangelism,
    setBrandRelationshipBrandEvangelism
  ] = useState(null);
  const [brandRelationshipWordOfMouth, setBrandRelationshipWordOfMouth] =
    useState(null);
  const [brandRelationshipBrandLove, setBrandRelationshipBrandLove] =
    useState(null);
  const [
    brandRelationshipBrandExperienceQuality,
    setBrandRelationshipBrandExperienceQuality
  ] = useState(null);
  const [
    brandRelationshipBrandExperienceBenefitCost,
    setBrandRelationshipBrandExperienceBenefitCost
  ] = useState(null);
  const [buyScoreData, setBuyScoreData] = useState(null);
  const [consumerBehavior, setConsumerBehavior] = useState(null);
  const [customerExperience, setCustomerExperience] = useState(null);
  const [elasticity, setElasticity] = useState(null);
  const [employeeBranding, setEmployeeBranding] = useState(null);
  const [inovation, setInovation] = useState(null);
  const [marketSuperiority, setMarketSuperiority] = useState(null);
  const [marketFit, setMarketFit] = useState(null);
  const [npsCalculatedData, setNpsCalculatedData] = useState(null);
  const [npsCrossingData, setNpsCrossingData] = useState(null);
  const [npsSimpleFrequencyData, setNpsSimpleFrequencyData] = useState(null);
  const [psychographic, setPsychographic] = useState(null);
  const { project, companyName } = useContext(userContext);

  const handleGenericData = async (
    objRequest,
    functionRequest,
    functionSet,
    filter
  ) => {
    let response = await functionRequest(objRequest, project, filter);
    functionSet(response);
  };

  const filterChart = (question, filter) => {
    switch (question) {
      case "brandRelationship":
        return handleGenericData(
          objBrandRelationship,
          requestSimpleFrequencyBrandRelationship,
          setBrandRelationship,
          filter
        );
      case "brandRelationshipBrandEvangelism":
        return handleGenericData(
          objBrandRelationshipBrandEvangelism,
          requestSimpleDataWithClassification,
          setBrandRelationshipBrandEvangelism,
          filter
        );
      case "brandRelationshipBrandLove":
        return handleGenericData(
          objBrandRelationshipBrandLove,
          requestSimpleDataWithClassification,
          setBrandRelationshipBrandLove,
          filter
        );
      case "brandRelationshipBrandExperienceQuality":
        return handleGenericData(
          objBrandRelationshipBrandExperienceQuality,
          requestSimpleDataWithClassification,
          setBrandRelationshipBrandExperienceQuality,
          filter
        );
      case "brandRelationshipBrandExperienceBenefitCost":
        return handleGenericData(
          objBrandRelationshipBrandExperienceBenefitCost,
          requestSimpleDataWithClassification,
          setBrandRelationshipBrandExperienceBenefitCost,
          filter
        );
      case "brandRelationshipWordOfMouth":
        return handleGenericData(
          objBrandRelationshipWordOfMouth,
          requestSimpleDataWithClassification,
          setBrandRelationshipWordOfMouth,
          filter
        );
      case "buyScoreData":
        return handleGenericData(
          objBuyScore,
          requestSimpleDataWithClassification,
          setBuyScoreData,
          filter
        );
      case "consumerBehavior":
        return handleGenericData(
          objConsumerBehavior,
          requestSimpleDataWithoutClassification,
          setConsumerBehavior,
          filter
        );
      case "customerExperience":
        return handleGenericData(
          objCustomerExperience,
          requestSimpleDataWithClassification,
          setCustomerExperience,
          filter
        );
      case "elasticity":
        return handleGenericData(
          objElasticity,
          requestSimpleDataWithoutClassification,
          setElasticity,
          filter
        );
      case "employeeBranding":
        return handleGenericData(
          objEmployeeBranding,
          requestSimpleDataWithClassification,
          setEmployeeBranding,
          filter
        );
      case "inovation":
        return handleGenericData(
          objInovation,
          requestSimpleDataWithClassification,
          setInovation,
          filter
        );
      case "marketSuperiority":
        return handleGenericData(
          objMarketSuperiority,
          requestSimpleDataWithClassification,
          setMarketSuperiority,
          filter
        );
      case "marketFit":
        return handleGenericData(
          objMarketFit,
          requestSimpleDataWithClassification,
          setMarketFit,
          filter
        );
      case "npsCalculatedData":
        return handleGenericData(
          null,
          requestAndSetNpsCalculatedData,
          setNpsCalculatedData,
          filter
        );
      case "npsCrossingData":
        return handleGenericData(
          null,
          requestAndSetNpsCrossingData,
          setNpsCrossingData,
          filter
        );
      case "npsSimpleFrequencyData":
        return handleGenericData(
          null,
          requestAndSetNpsSimpleFrequencyData,
          setNpsSimpleFrequencyData,
          filter
        );
      case "psychographic":
        return handleGenericData(
          null,
          requestSimpleFrequencyPsy,
          setPsychographic,
          filter
        );
      default:
        return;
    }
  };

  useEffect(() => {
    if (!brandRelationship) {
      filterChart("brandRelationship", "max");
    }
    if (!brandRelationshipBrandEvangelism) {
      filterChart("brandRelationshipBrandEvangelism", "max");
    }
    if (!brandRelationshipBrandLove) {
      filterChart("brandRelationshipBrandLove", "max");
    }
    if (!brandRelationshipWordOfMouth) {
      filterChart("brandRelationshipWordOfMouth", "max");
    }
    if (!brandRelationshipBrandExperienceQuality) {
      filterChart("brandRelationshipBrandExperienceQuality", "max");
    }
    if (!brandRelationshipBrandExperienceBenefitCost) {
      filterChart("brandRelationshipBrandExperienceBenefitCost", "max");
    }
    if (!buyScoreData) {
      filterChart("buyScoreData", "max");
    }
    if (!consumerBehavior) {
      filterChart("consumerBehavior", "max");
    }
    if (!customerExperience) {
      filterChart("customerExperience", "max");
    }
    if (!elasticity) {
      filterChart("elasticity", "max");
    }
    if (!employeeBranding) {
      filterChart("employeeBranding", "max");
    }
    if (!inovation) {
      filterChart("inovation", "max");
    }
    if (!marketSuperiority) {
      filterChart("marketSuperiority", "max");
    }
    if (!marketFit) {
      filterChart("marketFit", "max");
    }
    if (!npsCalculatedData) {
      filterChart("npsCalculatedData", "max");
    }
    if (!npsSimpleFrequencyData) {
      filterChart("npsSimpleFrequencyData", "max");
    }
    if (!npsCrossingData) {
      filterChart("npsCrossingData", "max");
    }
    if (!psychographic) {
      filterChart("psychographic", "max");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="content">
      <h1 className="title">Hazo Metrics</h1>

      {/* Headers */}
      <div className="block">
        <div className="blocksArea">
          {boxList.map((option, index) => {
            return (
              <a className="anchorBox" href={option.id}>
                <BoxChart values={option} key={`${option.title}_${index}`} />
              </a>
            );
          })}
        </div>
        <div className="explanationArea">
          <p>
            <strong>BuyScore:</strong> Prevê a probabilidade de um cliente
            continuar fazendo negócios com uma empresa nos próximos meses.
          </p>
          <p>
            <strong>NPS:</strong> Mede a disposição do cliente em recomendar uma
            empresa .
          </p>
          <p>
            <strong>Relacionamento de Marca:</strong> Avalia a percepção e
            lealdade do consumidor consolidando o Share of Voice, Share of Heart
            e Share of Mind, englobando comunicação, emoção, qualidade percebida
            e custo/benefício.
          </p>
          <p>
            <strong>Market Fit:</strong> Avaliar se o produto/serviço atual
            atende às expectativas e necessidades do cliente.
          </p>
          <p>
            <strong>Customer Experience:</strong> Explicar como medir a
            facilidade com que os clientes conseguem resolver demandas com a
            marca.
          </p>
          <p>
            <strong>Superioridade:</strong> Avaliar a percepção do cliente sobre
            a posição da marca em relação à concorrência e sua inovação.
          </p>
        </div>
      </div>
      <hr className="hr" />

      {/* Buyscore */}
      <div className="block" id="buyscore">
        <h2 className="title">BuyScore: Análise BuyScore por Cohorts</h2>
        <Crossing />
      </div>
      <div className="block">
        <h2 className="title">BuyScore: Série Histórica</h2>
        {buyScoreData && (
          <BarChart
            action={filterChart}
            data={buyScoreData}
            legend={true}
            question="buyScoreData"
            stack={true}
            title={`E você irá continuar como cliente da ${companyName} nos próximos 12 meses?`}
            top={100}
          />
        )}
      </div>
      <hr className="hr" />

      {/* Nps */}
      <div className="block" id="nps">
        <h2 className="title">Net Promoter Score (NPS)</h2>
        {npsCalculatedData && (
          <BarChart
            action={filterChart}
            data={npsCalculatedData}
            grid={true}
            legend={true}
            min={-100}
            question="npsCalculatedData"
            title={`Em uma escala de 0 a 10 o quanto você recomendaria a ${companyName} para um amigo ou familiar?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">
          Net Promoter Score Analítico: Promotores, Neutros, Detratores
        </h2>
        {npsSimpleFrequencyData && (
          <BarChart
            action={filterChart}
            data={npsSimpleFrequencyData}
            legend={true}
            question="npsSimpleFrequencyData"
            stack={true}
            title={`Em uma escala de 0 a 10 o quanto você recomendaria a ${companyName} para um amigo ou familiar?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">
          Net Promoter Score: Analítico x Buyscore x Indicaria (Boca a Boca)
        </h2>
        {npsCrossingData && (
          <BarChart
            action={filterChart}
            data={npsCrossingData}
            legend={true}
            question="npsCrossingData"
            title={`Em uma escala de 0 a 10 o quanto você recomendaria a ${companyName} para um amigo ou familiar?`}
            top={100}
          />
        )}
      </div>

      {/* Relacionamento de marca */}
      <div className="block" id="brandRelationship">
        <h2 className="title">Relacionamento de Marca: Série Histórica</h2>
        {brandRelationship && (
          <BarChart
            action={requestSimpleFrequencyBrandRelationship}
            data={brandRelationship}
            legend={true}
            obj={objBrandRelationship}
            question="brandRelationship"
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">Relacionamento de Marca</h2>
        <h3 className="subtitle">Share of Voice: Comunicação</h3>
        <h3 className="subtitle">
          Brand Evangelism (Comunicação: Notícia/Informação/Comentário)
        </h3>
        {brandRelationshipBrandEvangelism && (
          <BarChart
            action={filterChart}
            data={brandRelationshipBrandEvangelism}
            legend={true}
            question="brandRelationshipBrandEvangelism"
            stack={true}
            title={`Você viu alguma notícia, informação ou ouviu algum comentário sobre a ${companyName} recentemente que seja POSITIVO ou NEGATIVO?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">Relacionamento de Marca</h2>
        <h3 className="subtitle">Share of Voice: Comunicação</h3>
        <h3 className="subtitle">Boca a Boca (Word of Mouth)</h3>
        {brandRelationshipWordOfMouth && (
          <BarChart
            action={filterChart}
            data={brandRelationshipWordOfMouth}
            legend={true}
            question="brandRelationshipWordOfMouth"
            stack={true}
            title={`E você indicaria os serviços e produtos da ${companyName} a um amigo ou familiar?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">Relacionamento de Marca</h2>
        <h3 className="subtitle">Share of Heart: Emocional</h3>
        <h3 className="subtitle">Brand Love (Sentimento de Marca)</h3>
        {brandRelationshipBrandLove && (
          <BarChart
            action={filterChart}
            data={brandRelationshipBrandLove}
            legend={true}
            question="brandRelationshipBrandLove"
            stack={true}
            title={`E qual é o seu sentimento em relação à ${companyName}?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">Relacionamento de Marca</h2>
        <h3 className="subtitle">Share of Mind: Racional</h3>
        <h3 className="subtitle">Brand Experience (Percepção de Qualidade)</h3>
        {brandRelationshipBrandExperienceQuality && (
          <BarChart
            action={filterChart}
            data={brandRelationshipBrandExperienceQuality}
            legend={true}
            question="brandRelationshipBrandExperienceQuality"
            stack={true}
            title={`E você acredita que a ${companyName} possui produtos e serviços de qualidade?`}
            top={100}
          />
        )}
      </div>
      <div className="block">
        <h2 className="title">Relacionamento de Marca</h2>
        <h3 className="subtitle">Share of Mind: Racional</h3>
        <h3 className="subtitle">Brand Experience (Custo/Benefício)</h3>
        {brandRelationshipBrandExperienceBenefitCost && (
          <BarChart
            action={filterChart}
            data={brandRelationshipBrandExperienceBenefitCost}
            legend={true}
            question="brandRelationshipBrandExperienceBenefitCost"
            stack={true}
            title={`E como você avalia o custo/benefício da ${companyName}, pensando em custo benefício não sendo uma marca "barata", mas aquela que entrega a melhor relação de qualidade pelo preço?`}
            top={100}
          />
        )}
      </div>
      <hr className="hr" />

      {/* Market fit */}
      <div className="block" id="marketFit">
        <h2 className="title">Market fit</h2>
        {marketFit && (
          <BarChart
            action={filterChart}
            data={marketFit}
            legend={true}
            question="marketFit"
            stack={true}
            title={
              <div>
                <h3>E o produto que você possui é o ideal para você?</h3>
                <div>
                  <h5>
                    Não é o ideal, eu gostaria de um produto inferior, com custo
                    mais baixo (Downgrade),
                  </h5>
                  <h5>É o ideal,</h5>

                  <h5>
                    Não é o ideal, eu gostaria de um produto superior, mesmo que
                    com custo maior (Upgrade)
                  </h5>
                </div>
              </div>
            }
            top={100}
          />
        )}
      </div>

      {/* CES - Customer Effort Score */}
      <div className="block" id="customerExperience">
        <h2 className="title">CES - Customer Effort Score</h2>
        {customerExperience && (
          <BarChart
            action={filterChart}
            data={customerExperience}
            legend={true}
            question="customerExperience"
            stack={true}
            title={`Como você avalia a facilidade de solucionar a sua solicitação ou demanda com ${companyName}?`}
            top={100}
          />
        )}
      </div>

      {/* Superioridade de Mercado */}
      <div className="block" id="superioridade">
        <h2 className="title">Superioridade de Mercado</h2>
        <h3 className="subtitle">
          Contraste Comparativo da Percepção com Competidores
        </h3>
        {marketSuperiority && (
          <BarChart
            action={filterChart}
            data={marketSuperiority}
            legend={true}
            question="marketSuperiority"
            stack={true}
            title={`Agora pensando na ${companyName} em relação às outras empresas do mesmo segmento, você acredita que:`}
            top={100}
          />
        )}
      </div>

      {/* Uso e Comportamento */}
      <div className="block">
        <h2 className="title">Uso e Comportamento</h2>
        <h3 className="subtitle">Frequência de Uso</h3>
        {consumerBehavior && (
          <BarChart
            action={filterChart}
            data={consumerBehavior}
            description={
              <div className="legendBlock">
                <p>
                  <strong>Uso frequente e intensivo -</strong> Ele é uma parte
                  essencial do meu dia a dia
                </p>
                <p>
                  <strong>Uso regular -</strong> Eu uso consistentemente, mas
                  não é central para as minhas atividades diárias
                </p>
                <p>
                  <strong>Uso ocasional -</strong> Eu uso de vez em quando
                  quando necessário
                </p>
                <p>
                  <strong>Uso raro -</strong> Eu raramente uso, apenas em
                  situações específicas
                </p>
                <p>
                  <strong>Não uso atualmente -</strong> Eu comprei ou assinei,
                  mas não estou usando ativamente
                </p>
              </div>
            }
            legend={true}
            question="consumerBehavior"
            stack={true}
            title={`E como você descreveria o seu uso e interação com o seu plano de saúde ${companyName}?`}
            top={100}
          />
        )}
      </div>

      {/* Elasticidade (Sensibilidade a Mudanças de Preço) */}
      <div className="block">
        <h2 className="title">
          Elasticidade (Sensibilidade a Mudanças de Preço)
        </h2>
        {elasticity && (
          <BarChart
            action={filterChart}
            data={elasticity}
            description={
              <div className="legendBlock">
                <p>
                  <strong>Muito sensível:</strong> Mesmo uma pequena mudança de
                  preço me faria reconsiderar minha decisão de compra
                </p>
                <p>
                  <strong>Moderadamente sensível:</strong> Mudanças moderadas de
                  preço poderiam influenciar minha decisão de compra
                </p>
                <p>
                  <strong>Pouco sensível:</strong> Somente mudanças
                  significativas de preço afetariam minha decisão de compra
                </p>
                <p>
                  <strong>Nada sensível:</strong> Mudanças de preço não têm
                  impacto na minha decisão de compra; outros fatores são mais
                  importantes para mim
                </p>
              </div>
            }
            legend={true}
            question="elasticity"
            stack={true}
            title={`E como uma mudança de preços afetaria a sua decisão de continuar cliente da ${companyName}?`}
            top={100}
          />
        )}
      </div>

      {/* Inovação */}
      <div className="block">
        <h2 className="title">Inovação</h2>
        <h3 className="subtitle">
          Percepção de Inovação e Adequação a Mudanças
        </h3>
        {inovation && (
          <BarChart
            action={filterChart}
            data={inovation}
            legend={true}
            question="inovation"
            stack={true}
            title={`E você acredita que a ${companyName} é inovadora?`}
            top={100}
          />
        )}
      </div>

      {/* Employee Branding */}
      <div className="block">
        <h2 className="title">Employee Branding</h2>
        <h3 className="subtitle">
          Brand Trust (Confiança e Reputação de Marca como Empregador)
        </h3>
        {employeeBranding && (
          <BarChart
            action={filterChart}
            data={employeeBranding}
            legend={true}
            question="employeeBranding"
            stack={true}
            title={`E, se você fosse trabalhar na ${companyName}, você ficaria orgulhoso de trabalhar em fazer parte da nossa empresa?`}
            top={100}
          />
        )}
      </div>

      {/* Analise psicografica */}
      <div className="block">
        <h2 className="title">Análise Psicográfica</h2>
        {psychographic && (
          <BarChart
            action={filterChart}
            data={psychographic}
            description={
              <div className="legendBlock">
                <p>
                  <strong>Conveniência:</strong> Eu valorizo a facilidade de uso
                  e acessibilidade
                </p>
                <p>
                  <strong>Qualidade:</strong> Eu busco os melhores
                  produtos/serviços disponíveis
                </p>
                <p>
                  <strong>Preço:</strong> Eu sou motivado principalmente pelo
                  custo-benefício
                </p>
                <p>
                  <strong>Status:</strong> Eu gosto de produtos/serviços que
                  elevam meu status social
                </p>
                <p>
                  <strong>Sustentabilidade:</strong> Eu prefiro
                  produtos/serviços ambientalmente responsáveis
                </p>
                <p>
                  <strong>Inovação:</strong> Eu sou atraído por novidades e
                  tecnologia de ponta
                </p>
                <p>
                  <strong>Personalização:</strong> Eu valorizo produtos/serviços
                  que podem ser personalizados para minhas necessidades
                </p>
                <p>
                  <strong>Comunidade:</strong> Eu escolho marcas que promovem um
                  senso de comunidade ou apoiam causas sociais
                </p>
              </div>
            }
            question="psychographic"
            legend={true}
            title={`Qual a razão principal que você escolheu a ${companyName}?`}
          />
        )}
      </div>
    </div>
  );
}

export default Metrics;
