import React from "react";
import hazoScare from "../../assets/img/hazo_ai.png";
import { Link } from "react-router-dom";
import "./style.scss";

const NotFound = () => {
  return (
    <section className="compNotFound">
      <div className="container">
        <h2>Você seperdeu?</h2>
        <img className="hazoImg" src={hazoScare} alt="camaleão hazo" />
        <p>Infelizmente não encontramos essa página.</p>
        <p>Por favor, clique no link abaixo para voltar ao início.</p>
        <p>
          <Link to="/">Página Inicial</Link>
        </p>
      </div>
    </section>
  );
};

export default NotFound;
