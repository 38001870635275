import "./style.scss";
import React, { useContext, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
// import ArrowDownBlack from "../../../assets/img/chart_arrow_down_black.png";
// import ArrowDownWhite from "../../../assets/img/chart_arrow_down_white.png";
// import ArrowUpBlack from "../../../assets/img/chart_arrow_up_black.png";
// import ArrowUpWhite from "../../../assets/img/chart_arrow_up_white.png";
import MetricsService from "../../../services/metricsService";
import { userContext } from "../../../context/User";

function BoxChart(props) {
  const { color, inverter, multiple, options, question, questions, title } =
    props.values;
  const [dataDoughnut, setDataDoughnut] = useState(null);
  const [optionsDoughnut, setOptionsDoughnut] = useState(null);
  const [percent, setPercent] = useState(null);
  const [loading, setLoading] = useState(true);
  const { project } = useContext(userContext);

  useEffect(() => {
    const getDataBox = async () => {
      let percent = 0;
      if (!multiple) {
        let response = await MetricsService.getBoxData({
          answer: options,
          project: project,
          question_text: question
        });
        if (response.data.percent) {
          percent = response.data.percent;
        } else {
          percent = 0;
        }
      } else {
        let sum = 0;
        for (let i = 0; i < questions.length; i++) {
          let response = await MetricsService.getBoxData({
            answer: questions[i].options,
            project: project,
            question_text: questions[i].question
          });
          sum = parseInt(sum) + parseInt(response.data.percent);
        }

        if (isNaN(sum)) {
          percent = 0;
        } else {
          percent = (sum / questions.length).toFixed(0);
        }
      }

      setDataDoughnut({
        labels: [title],
        datasets: [
          {
            label: title,
            data: [percent, 100 - percent],
            backgroundColor: [inverter ? "white" : color, "#1e1f21"],
            borderColor: [inverter ? "white" : color, "#1e1f21"],
            borderWidth: 1
          }
        ]
      });

      setOptionsDoughnut({
        cutout: 20,
        plugins: {
          datalabels: {
            display: false
          },
          legend: {
            display: false
          }
        }
      });

      setPercent(percent);

      setLoading(false);
    };

    if (loading) {
      getDataBox();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return "Loading";
  } else {
    return (
      <div
        className="compBoxChart"
        style={{ backgroundColor: inverter ? color : null }}
      >
        <span className="titleBoxChart">{title}</span>

        <div className="contentBoxChart">
          <div className="informationsBoxChart">
            <span className="percentBoxChart">
              {percent ? percent : "00"} %
            </span>
          </div>
          <div className="chartBoxChart">
            <Doughnut data={dataDoughnut} options={optionsDoughnut} />
          </div>
        </div>
      </div>
    );
  }
}

export default BoxChart;
