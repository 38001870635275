import "./style.scss";

function FilterSelection(props) {
  const { action, filter, question, setFilter } = props;

  const handleFilter = (value, filtered) => {
    setFilter(value);
    action(question, filtered);
  };

  return (
    <div className="compFilterSelection">
      <ul className="groupNavigation">
        <li
          className={`groupItem ${filter === "1M" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("1M", 1)}
        >
          1M
        </li>
        <li
          className={`groupItem ${filter === "3M" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("3M", 3)}
        >
          3M
        </li>
        <li
          className={`groupItem ${filter === "6M" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("6M", 6)}
        >
          6M
        </li>
        <li
          className={`groupItem ${filter === "YTD" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("YTD", "ytd")}
        >
          YTD
        </li>
        <li
          className={`groupItem ${filter === "1Y" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("1Y", 12)}
        >
          1Y
        </li>
        <li
          className={`groupItem ${filter === "2Y" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("2Y", 24)}
        >
          2Y
        </li>
        <li
          className={`groupItem ${filter === "MAX" ? "groupItemSelected" : ""}`}
          onClick={() => handleFilter("MAX", "max")}
        >
          Max
        </li>
      </ul>
    </div>
  );
}

export default FilterSelection;
