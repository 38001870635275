import { useContext, useEffect, useRef, useState } from "react";
import InsightsService from "../../services/insightsService";
import Arrow from "../../assets/img/arrow_up_white.png";
import Hazo from "../../assets/img/hazo_ai_croped.png";
import Perfil from "../../assets/img/perfil.png";
import "./style.scss";
import { userContext } from "../../context/User";
import Markdown from "react-markdown";
import TypingText from "../typingText";

function InsightsChat(props) {
  const { chatSelected } = props;
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [waitingResponse, setWaitingResponse] = useState(false);
  const { project } = useContext(userContext);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // Call service to get messages
    if (messages.length === 0) {
      handleGetMessages();
    }
    // eslint-disable-next-line
  }, [messages]);

  useEffect(() => {
    resetMessages();
  }, [chatSelected]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const resetMessages = () => {
    setMessages([]);
  };

  const handleGetMessages = async () => {
    // Call service to get messages
    const response = await InsightsService.getChatsData({
      project: project,
      id_chat: chatSelected
    });

    if (Array.isArray(response.data)) {
      let arrayMessages = response.data.map((element) => {
        return { who: element.who, message: element.message };
      });
      setMessages(arrayMessages);
    }
  };

  const sendMessage = async () => {
    setWaitingResponse(true);
    let text = inputText;
    handleAddUserMessage(inputText);
    setInputText("");
    let response = await InsightsService.promptConversation({
      id_chat: chatSelected,
      project: project,
      text_question: text
    });

    if (response.data) {
      handleAddIaMessage(response.data.response);
      setWaitingResponse(false);
    } else {
      setError(true);
      setErrorMessage(
        <div>Algo de errado aconteceu, tente novamente mais tarde</div>
      );
    }
  };

  const handleAddUserMessage = (message) => {
    setMessages((prev) => {
      return [...prev, { who: "user", message: message }];
    });
  };

  const handleAddIaMessage = (message) => {
    setMessages((prev) => {
      return [...prev, { who: "ia", message: message, typing: true }];
    });
  };

  return (
    <div className="chatComp">
      <div className="chatArea">
        {messages.length > 0 &&
          messages.map((element, index) => {
            return (
              <div className="messageArea" key={index}>
                {element.who === "user" ? (
                  <>
                    <img src={Perfil} alt="avatar" className="iconHazo" />
                    <div className="message">
                      <span>You:</span>
                      <p>{element.message}</p>
                    </div>
                  </>
                ) : (
                  <>
                    <img src={Hazo} alt="hazo" className="iconHazo" />
                    <div className="message">
                      <span>IA:</span>
                      {element.typing ? (
                        <div>
                          <TypingText text={element.message} speed={10} />
                        </div>
                      ) : (
                        <p>
                          <Markdown>{element.message}</Markdown>
                        </p>
                      )}
                    </div>
                  </>
                )}
              </div>
            );
          })}
        {messages.length === 0 && (
          <div className="messageArea">
            <img src={Hazo} alt="hazo" className="iconHazo" />
            <div className="message">
              <span>IA:</span>
              Olá, eu sou o hazo.ai. Aqui você pode me fazer perguntas sobre o
              relatório, por exemplo:
              <br /> - Quais estratégias eu posso fazer para melhorar o nps no
              mês de Fevereiro?
              <br /> - Quais os Insights do mês de Março?
            </div>
          </div>
        )}
        {waitingResponse && (
          <div className="messageArea">
            <img src={Hazo} alt="hazo" className="iconHazo" />
            <div className="message">
              <span>IA:</span>
              <br />
              Estou gerando sua resposta, aguarde um pouco.
            </div>
          </div>
        )}
        {error && errorMessage}
        <div ref={messagesEndRef} />
      </div>{" "}
      <div className="inputContainer">
        <input
          type="text"
          id="message-input"
          placeholder="Digite sua mensagem..."
          onChange={(event) => setInputText(event.target.value)}
          value={inputText}
        />

        {!waitingResponse ? (
          <button onClick={() => sendMessage()} className="button">
            <img src={Arrow} alt="sent" className="iconSent" />
          </button>
        ) : (
          <button className="buttonDisabled">
            <img src={Arrow} alt="sent" className="iconSent" />
          </button>
        )}
      </div>
    </div>
  );
}

export default InsightsChat;
