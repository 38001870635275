import { firebaseAuth } from "../config/firebase";
import Axios from "axios";
export const Auth = firebaseAuth;

export default class generalService {
  static getProject = async () => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}/v1/analytics/general/project/?user=${email}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getUserProfile = async () => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}/v1/analytics/general/user_profile/?user=${email}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
