import React, { useState, useEffect } from "react";
import Markdown from "react-markdown";

const TypingText = ({ scrollToBottom = false, speed, text }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < text.length - 1) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        if (scrollToBottom) {
          scrollToBottom();
        }
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed, scrollToBottom]);

  return <Markdown>{displayText}</Markdown>;
};

export default TypingText;
