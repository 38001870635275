const boxList = [
  {
    color: "#5d55fa",
    description: (
      <p>
        <strong>BuyScore:</strong> Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Cras ultricies mauris ac urna lacinia sollicitudin.
        Fusce vel elit vitae ligula faucibus dictum a quis erat. Sed ullamcorper
        dui vel erat malesuada, nec rhoncus enim euismod.
      </p>
    ),
    id: "#buyscore",
    inverter: true,
    options: ["Sim"],
    question:
      "E você irá continuar como cliente da nossa marca nos próximos 12 meses?",
    title: "BuyScore"
  },
  {
    color: "#f86c69",
    description: (
      <p>
        <strong>NPS:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing
        elit. Cras ultricies mauris ac urna lacinia sollicitudin. Fusce vel elit
        vitae ligula faucibus dictum a quis erat. Sed ullamcorper dui vel erat
        malesuada, nec rhoncus enim euismod.
      </p>
    ),
    id: "#nps",
    inverter: false,
    options: ["9", "10"],
    question:
      "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?",
    title: "NPS"
  },
  {
    color: "#c630d2",
    description: (
      <p>
        <strong>Relacionamento de Marca:</strong> Lorem ipsum dolor sit amet,
        consectetur adipiscing elit. Cras ultricies mauris ac urna lacinia
        sollicitudin. Fusce vel elit vitae ligula faucibus dictum a quis erat.
        Sed ullamcorper dui vel erat malesuada, nec rhoncus enim euismod. Ut
        vitae dignissim quam, eget congue risus. Aliquam vel luctus lectus.
        Praesent lorem lacus, efficitur id risus nec, volutpat pulvinar felis.
        Nullam euismod porttitor velit, vitae mattis nunc rutrum sit amet.
        Aenean sed elit tortor
      </p>
    ),
    id: "#brandRelationship",
    inverter: false,
    multiple: true,
    questions: [
      {
        options: ["Sim, POSITIVO"],
        question:
          "sobre a nossa marca recentemente que seja POSITIVO ou NEGATIVO?"
      },
      {
        options: ["Sim"],
        question:
          "E você indicaria os serviços e produtos da nossa marca a um amigo ou familiar?"
      },
      {
        options: ["Sim"],
        question: "da nossa marca nos próximos 12 meses?"
      },
      {
        options: [
          "Somente mudanças significativas de preço afetariam minha decisão de compra",
          "Mudanças de preço não têm impacto na minha decisão de compra; outros fatores são mais importantes para mim"
        ],
        question:
          "como uma mudança de preços afetaria a sua decisão de continuar"
      },
      {
        options: ["Sentimento Positivo"],
        question: "E qual é o seu sentimento em relação à nossa marca?"
      },
      {
        options: ["Possui um bom custo benefício"],
        question:
          'E como você avalia o custo/benefício da nossa marca, pensando em custo benefício não sendo uma marca "barata", mas aquela que entrega a melhor relação de qualidade pelo preço?'
      },
      {
        options: ["SIM, possui produtos/serviços de qualidade"],
        question:
          "E você acredita que a nossa marca possui produtos e serviços de qualidade?"
      }
    ],
    title: (
      <div>
        Relacionamento
        <br /> de Marca
      </div>
    )
  },
  {
    color: "#1a73e8",
    description: (
      <p>
        <strong>Market Fit:</strong> Avaliar se o produto/serviço atual atende
        às expectativas e necessidades do cliente.
      </p>
    ),
    id: "#marketFit",
    inverter: false,
    options: ["É o ideal"],
    question: "produto que você possui é o ideal para você?",
    title: "Market Fit"
  },
  {
    color: "#24aed6",
    description: (
      <p>
        <strong>Customer Experience:</strong> Explicar como medir a facilidade
        com que os clientes conseguem resolver demandas com a marca.
      </p>
    ),
    id: "#customerExperience",
    inverter: false,
    options: ["Extremamente Fácil", "Fácil"],
    question:
      "Como você avalia a facilidade de solucionar a sua solicitação ou demanda com a nossa marca?",
    title: "Customer Experience"
  },
  {
    color: "#feed38",
    description: (
      <p>
        <strong>Superioridade:</strong> Avaliar a percepção do cliente sobre a
        posição da marca em relação à concorrência e sua inovação.
      </p>
    ),
    id: "#superioridade",
    inverter: false,
    options: ["A nossa marca é SUPERIOR as outras"],
    question: "nossa marca em relação às outras empresas do mesmo segmento",
    title: "Superioridade"
  }
];

const objBrandRelationship = {
  color: "#c630d2",
  inverter: false,
  multiple: true,
  questions: [
    {
      options: ["Sim, POSITIVO"],
      question:
        "sobre a nossa marca recentemente que seja POSITIVO ou NEGATIVO?"
    },
    {
      options: ["Sim"],
      question:
        "E você indicaria os serviços e produtos da nossa marca a um amigo ou familiar?"
    },
    {
      options: ["Sim"],
      question: "da nossa marca nos próximos 12 meses?"
    },
    {
      options: [
        "Somente mudanças significativas de preço afetariam minha decisão de compra",
        "Mudanças de preço não têm impacto na minha decisão de compra; outros fatores são mais importantes para mim"
      ],
      question: "como uma mudança de preços afetaria a sua decisão de continuar"
    },
    {
      options: ["Sentimento Positivo"],
      question: "E qual é o seu sentimento em relação à nossa marca?"
    },
    {
      options: ["Possui um bom custo benefício"],
      question:
        'E como você avalia o custo/benefício da nossa marca, pensando em custo benefício não sendo uma marca "barata", mas aquela que entrega a melhor relação de qualidade pelo preço?'
    },
    {
      options: ["SIM, possui produtos/serviços de qualidade"],
      question:
        "E você acredita que a nossa marca possui produtos e serviços de qualidade?"
    }
  ],
  title: (
    <div>
      Relacionamento
      <br /> de Marca
    </div>
  )
};

const objBrandRelationshipBrandEvangelism = {
  color: "#c630d2",
  question: {
    answer_negative: ["Sim, NEGATIVO"],
    alias_negative: ["Negativa"],
    answer_neutral: ["Não ouvi falar nada"],
    alias_neutral: ["Não ouviu falar"],
    answer_positive: ["Sim, POSITIVO"],
    alias_positive: ["Positiva"],
    question_query: "marca recentemente que seja POSITIVO ou NEGATIVO?",
    title: "Brand Evangelism (Comunicação: Notícia/Informação/Comentário)"
  },
  subtitle: "Share of Voice: Comunicação",
  title: "Relacionamento de Marca"
};

const objBrandRelationshipWordOfMouth = {
  color: "#c630d2",
  question: {
    answer_negative: ["Não"],
    alias_negative: "Indicaria",
    answer_positive: ["Sim"],
    alias_positive: "Não Indicaria",
    question_query:
      "E você indicaria os serviços e produtos da nossa marca a um amigo ou familiar?",
    title: "Boca a Boca (Word of Mouth)"
  },
  subtitle: "Share of Voice: Comunicação",
  title: "Relacionamento de Marca"
};

const objBrandRelationshipBrandLove = {
  color: "#c630d2",
  question: {
    answer_negative: ["Sentimento Negativo"],
    answer_positive: ["Sentimento Positivo"],
    title: "Brand Love (Sentimento de Marca)",
    question_query: "E qual é o seu sentimento em relação à nossa marca?"
  },
  subtitle: "Share of Heart: Emocional",
  title: "Relacionamento de Marca"
};

const objBrandRelationshipBrandExperienceQuality = {
  color: "#c630d2",
  question: {
    answer_negative: ["NÃO possui produtos/serviços de qualidade"],
    alias_negative: "Não (qualidade)",
    answer_positive: ["SIM, possui produtos/serviços de qualidade"],
    alias_positive: "Sim (qualidade)",
    question_query:
      "E você acredita que a nossa marca possui produtos e serviços de qualidade?",
    title: "Brand Experience (Percepção de Qualidade)"
  },
  subtitle: "Share of Mind: Racional",
  title: "Relacionamento de Marca"
};

const objBrandRelationshipBrandExperienceBenefitCost = {
  color: "#c630d2",
  question: {
    answer_negative: ["Não possui um bom custo benefício"],
    alias_negative: "Não (custo/benefício)",
    answer_positive: ["Possui um bom custo benefício"],
    alias_positive: "Sim (custo/benefício)",
    question_query:
      'E como você avalia o custo/benefício da nossa marca, pensando em custo benefício não sendo uma marca "barata", mas aquela que entrega a melhor relação de qualidade pelo preço?',
    title: "Brand Experience (Custo/Benefício)"
  },
  subtitle: "Share of Mind: Racional",
  title: "Relacionamento de Marca"
};

const objBuyScore = {
  color: "#c630d2",
  question: {
    answer_negative: ["Não"],
    answer_neutral: ["Não sei"],
    answer_positive: ["Sim"],
    question_query:
      "E você irá continuar como cliente da nossa marca nos próximos 12 meses?",
    subtitle: "Buyscore - Remarketing"
  },
  title: "Share of Wallet: Transactional Component"
};

const objConsumerBehavior = {
  color: "#5d55fa",
  question: {
    answer_negative: [
      "Uso raro - Eu raramente uso, apenas em situações específicas",
      "Não uso atualmente - Eu comprei ou assinei, mas não estou usando ativamente"
    ],
    answer_neutral: [
      "Uso ocasional - Eu uso de vez em quando quando necessário"
    ],
    answer_positive: [
      "Uso frequente e intensivo - Ele é uma parte essencial do meu dia a dia",
      "Uso regular - Eu uso consistentemente, mas não é central para as minhas atividades diárias"
    ],
    question_query:
      "como você descreveria o seu uso e interação com o nosso produto/serviço?"
  },
  subtitle: "Frequência de Uso",
  title: "Uso e Comportamento"
};

const objCustomerExperience = {
  color: "#24aed6",
  question: {
    answer_negative: ["Difícil", "Extremamente Difícil"],
    alias_negative: "CES (Difícil, Ext. Difícil)",
    answer_neutral: ["Normal", "Não tive um atendimento recente"],
    alias_neutral: "Normal",
    answer_positive: ["Extremamente Fácil", "Fácil"],
    alias_positive: "CES (Fácil, Ext. Fácil)",
    question_query:
      "Como você avalia a facilidade de solucionar a sua solicitação ou demanda com a nossa marca?",
    subtitle: "CES (Customer Effort Score) - Nível de Esforço do Cliente"
  },
  title: "Customer Experience"
};

const objElasticity = {
  color: "#c630d2",
  question: {
    question_query:
      "como uma mudança de preços afetaria a sua decisão de continuar"
  },
  title: "Elasticidade (Sensibilidade a Mudanças de Preço)"
};

const objEmployeeBranding = {
  color: "#5d55fa",
  question: {
    answer_negative: ["Não, eu ficaria envergonhado"],
    alias_negative: "Não",
    answer_neutral: ["Não sei avaliar"],
    alias_neutral: "Não sei avaliar",
    answer_positive: ["Sim, eu ficaria orgulhoso"],
    alias_positive: "Sim",
    question_query:
      "E, se você fosse trabalhar na nossa marca, você ficaria orgulhoso de trabalhar em fazer parte da nossa empresa?",
    subtitle: "Brand Trust (Confiança e Reputação de Marca como Empregador)"
  },
  title: "Employee Branding (Reputação de Marca como Empregador)"
};

const objInovation = {
  color: "#5d55fa",
  question: {
    answer_negative: ["Não"],
    alias_negative: "Não",
    answer_neutral: ["Não sei avaliar"],
    alias_neutral: "Não sei avaliar",
    answer_positive: ["Sim"],
    alias_positive: "Sim",
    question_query: "E você acredita que a nossa marca é inovadora?"
  },
  subtitle: "Percepção de Inovação e Adequação a Mudanças",
  title: "Inovação"
};

const objMarketFit = {
  color: "#1a73e8",
  question: {
    answer_negative: [
      "Não é o ideal, eu gostaria de um produto inferior, com custo mais baixo"
    ],
    alias_negative: "Downgrade",
    answer_neutral: ["É o ideal"],
    alias_neutral: "Ideal",
    answer_positive: [
      "Não é o ideal, eu gostaria de um produto superior, mesmo que com custo maior"
    ],
    alias_positive: "Upgrade",
    subtitle: "Product Fit (Adequação Produto ao Mercado)",
    question_query: "o produto que você possui é o ideal para você?",
    question_text: (
      <div>
        <h3>E o produto que você possui é o ideal para você?</h3>
        <div>
          <h6>
            Não é o ideal, eu gostaria de um produto inferior, com custo mais
            baixo (Downgrade),
          </h6>
          <h6>É o ideal,</h6>

          <h6>
            Não é o ideal, eu gostaria de um produto superior, mesmo que com
            custo maior (Upgrade)
          </h6>
        </div>
      </div>
    )
  },
  title: "Market Fit"
};

const objMarketSuperiority = {
  color: "#feed38",
  question: {
    answer_negative: ["A nossa marca é INFERIOR as outras"],
    alias_negative: "Inferior",
    answer_neutral: ["A nossa marca é IGUAL as outras"],
    alias_neutral: "Igual",
    answer_positive: ["A nossa marca é SUPERIOR as outras"],
    alias_positive: "Superior",
    question_query:
      "Agora pensando em nossa marca em relação às outras empresas do mesmo segmento"
  },
  subtitle: "Contraste Comparativo da Percepção com Competidores",
  title: "Superioridade de Mercado"
};

export {
  boxList,
  objBuyScore,
  objBrandRelationship,
  objBrandRelationshipBrandEvangelism,
  objBrandRelationshipWordOfMouth,
  objBrandRelationshipBrandLove,
  objBrandRelationshipBrandExperienceQuality,
  objBrandRelationshipBrandExperienceBenefitCost,
  objConsumerBehavior,
  objCustomerExperience,
  objMarketSuperiority,
  objElasticity,
  objEmployeeBranding,
  objInovation,
  objMarketFit
};
