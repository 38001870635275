import { firebaseAuth } from "../config/firebase";
export const Auth = firebaseAuth;

export default class authService {
  static sendEmailVerification = () => {
    firebaseAuth.currentUser.sendEmailVerification();
  };

  static getUserAuth = async () => {
    return firebaseAuth.currentUser.email;
  };

  static signUp = async (userData) => {
    let response = null;
    await firebaseAuth
      .createUserWithEmailAndPassword(userData.email, userData.password)
      .then(() => {
        response = true;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static signIn = async (userData) => {
    let response = null;
    let errorMessage = null;
    await firebaseAuth
      .signInWithEmailAndPassword(userData.email, userData.password)
      .then((objectAuth) => {
        response = objectAuth.user;
      })
      .catch((error) => {
        errorMessage = error.message;
      });
    return { response, errorMessage };
  };

  static signOut = () => {
    firebaseAuth.signOut();
  };

  static resetPassword = async (userData) => {
    const user = firebaseAuth.currentUser;
    const credential = firebaseAuth.EmailAuthProvider.credential(
      userData.email,
      userData.password
    );
    let status = {};
    await user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        await user
          .updatePassword(userData.newPassword)
          .then(async () => {
            status = {
              message: "Senha alterada com sucesso!",
              type: "success"
            };
          })
          .catch(() => {
            status = {
              message: "Não foi possível alterar a senha!",
              type: "fail"
            };
          });
      })
      .catch(() => {
        status = {
          message: "Senha atual incorreta!",
          type: "fail"
        };
      });

    return status;
  };

  static sendEmailToResetPassword = async (email) => {
    let status = {};

    await firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        status = {
          message: "Resent",
          code: "success"
        };
      })
      .catch((error) => {
        status = {
          message: error.message,
          code: error.code
        };
      });

    return status;
  };
}
