import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/User";
import AllRoutes from "./routes/index";

function App() {
  return (
    <div className="app">
      <AuthProvider>
        <BrowserRouter>
          <AllRoutes />
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
