import { firebaseAuth } from "../config/firebase";
import Axios from "axios";
export const Auth = firebaseAuth;

export default class metricsService {
  static getBoxData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/box_data/`,
      data: JSON.stringify({
        answer: object.answer,
        project: object.project,
        question_text: object.question_text,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getCrossingData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/crossing/`,
      data: JSON.stringify({
        answer: object.answer,
        project: object.project,
        question_text: object.question_text,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getCrossingDataNps = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/crossing_data_nps/`,
      data: JSON.stringify({
        answer: object.answer,
        project: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getCrossingDataDefault = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/crossing_default/`,
      data: JSON.stringify({
        project: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getSimpleFrequency = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/simple_frequency/`,
      data: JSON.stringify({
        filter: object.filter,
        project: object.project,
        question_text: object.question_text,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getSimpleFrequencyBrand = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/simple_frequency_brand/`,
      data: JSON.stringify({
        filter: object.filter,
        project: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getSimpleFrequencyNps = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/simple_frequency_nps/`,
      data: JSON.stringify({
        filter: object.filter,
        project: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getSimpleFrequencyPsy = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/metrics/simple_frequency_psy/`,
      data: JSON.stringify({
        filter: object.filter,
        project: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
