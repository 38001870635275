const optionsCrossing = [
  {
    title: "NPS",
    items: [
      {
        name: "NPS - Promotores",
        options: ["promotor"],
        question:
          "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?"
      },
      {
        name: "NPS - Neutros",
        options: ["neutro"],
        question:
          "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?"
      },
      {
        name: "NPS - Detratores",
        options: ["detrator"],
        question:
          "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?"
      }
    ]
  },
  {
    title: (
      <span>
        Brand Evangelism (Share of Voice)
        <br />{" "}
        <span style={{ fontSize: "12px" }}>
          (Comunicação: Notícia/Informação/Comentário)
        </span>
      </span>
    ),
    items: [
      {
        name: "Comunicação - Positivo",
        options: ["Sim, POSITIVO"],
        question:
          "Você viu alguma notícia, informação ou ouviu algum comentário sobre a nossa marca recentemente que seja POSITIVO ou NEGATIVO"
      },
      {
        name: "Comunicação - Negativo",
        options: ["Sim, NEGATIVO"],
        question:
          "Você viu alguma notícia, informação ou ouviu algum comentário sobre a nossa marca recentemente que seja POSITIVO ou NEGATIVO"
      },
      {
        name: "Comunicação - Não ouvi falar nada",
        options: ["Não ouvi falar nada"],
        question:
          "Você viu alguma notícia, informação ou ouviu algum comentário sobre a nossa marca recentemente que seja POSITIVO ou NEGATIVO"
      }
    ]
  },
  {
    title: "Boca a Boca (Share of Voice)",
    items: [
      {
        name: "Indica - Sim ",
        options: ["Sim"],
        question:
          "E você indicaria os serviços e produtos da nossa marca a um amigo ou familiar?"
      },
      {
        name: "Indica - Não",
        options: ["Não"],
        question:
          "E você indicaria os serviços e produtos da nossa marca a um amigo ou familiar?"
      }
    ]
  },
  {
    title: "Brand Love (Emocional)",
    items: [
      {
        name: "Sentimento Positivo",
        options: ["Sentimento Positivo"],
        question: "E qual é o seu sentimento em relação à nossa marca?"
      },
      {
        name: "Sentimento Negativo",
        options: ["Sentimento Negativo"],
        question: "E qual é o seu sentimento em relação à nossa marca?"
      }
    ]
  },
  {
    title: "Brand Experience (Racional)",
    items: [
      {
        name: "Produtos e serviços de qualidade - Sim",
        options: ["SIM, possui produtos/serviços de qualidade"],
        question:
          "E você acredita que a nossa marca possui produtos e serviços de qualidade?"
      },
      {
        name: "Produtos e serviços de qualidade - Não",
        options: ["NÃO possui produtos/serviços de qualidade"],
        question:
          "E você acredita que a nossa marca possui produtos e serviços de qualidade?"
      },
      {
        name: "Custo/beneficio - Possui",
        options: ["Possui um bom custo benefício"],
        question: "E como você avalia o custo/benefício da nossa marca"
      },
      {
        name: "Custo/beneficio - Não Possui",
        options: ["Não possui um bom custo benefício"],
        question: "E como você avalia o custo/benefício da nossa marca"
      }
    ]
  },

  {
    title: "Market Fit",
    items: [
      {
        name: "Market Fit - Ideal",
        options: ["É o ideal"],
        question: "produto que você possui é o ideal para você?"
      },
      {
        name: "Sem Market Fit (Upgrade + Downgrade)",
        options: [
          "Não é o ideal, eu gostaria de um produto superior, mesmo que com custo maior",
          "Não é o ideal, eu gostaria de um produto inferior, com custo mais baixo"
        ],
        question: "produto que você possui é o ideal para você?"
      },
      {
        name: "Sem Market Fit - Upgrade",
        options: [
          "Não é o ideal, eu gostaria de um produto superior, mesmo que com custo maior"
        ],
        question: "produto que você possui é o ideal para você?"
      },
      {
        name: "Sem Market Fit - Downgrade",
        options: [
          "Não é o ideal, eu gostaria de um produto inferior, com custo mais baixo"
        ],
        question: "produto que você possui é o ideal para você?"
      }
    ]
  },
  {
    title: (
      <span>
        CES - Customer Effort Score
        <br />
        (Nível de Esforço)
      </span>
    ),
    items: [
      {
        name: "CES (Fácil+Ext.Fácil)",
        options: ["Extremamente Fácil", "Fácil"],
        question:
          "Como você avalia a facilidade de solucionar a sua solicitação ou demanda com a nossa marca?"
      },
      {
        name: "CES (Normal)",
        options: ["Normal"],
        question:
          "Como você avalia a facilidade de solucionar a sua solicitação ou demanda com a nossa marca?"
      },
      {
        name: "CES (Difícil + Ext. Difícil)",
        options: ["Difícil", "Extremamente Difícil"],
        question:
          "Como você avalia a facilidade de solucionar a sua solicitação ou demanda com a nossa marca?"
      }
    ]
  },
  {
    title: "Superioridade",
    items: [
      {
        name: "Superior",
        options: ["A nossa marca é SUPERIOR as outras"],
        question: "nossa marca em relação às outras empresas do mesmo segmento"
      },
      {
        name: "Igual",
        options: ["A nossa marca é IGUAL as outras"],
        question: "nossa marca em relação às outras empresas do mesmo segmento"
      },
      {
        name: "Inferior",
        options: ["A nossa marca é INFERIOR as outras"],
        question: "nossa marca em relação às outras empresas do mesmo segmento"
      }
    ]
  },

  {
    title: "Uso e Comportamento",
    items: [
      {
        name: "Uso frequente e intensivo",
        options: [
          "Uso frequente e intensivo - Ele é uma parte essencial do meu dia a dia"
        ],
        question:
          "como você descreveria o seu uso e interação com o nosso produto/serviço?"
      },
      {
        name: "Uso regular",
        options: [
          "Uso regular - Eu uso consistentemente, mas não é central para as minhas atividades diárias"
        ],
        question:
          "como você descreveria o seu uso e interação com o nosso produto/serviço?"
      },
      {
        name: "Uso ocasional",
        options: ["Uso ocasional - Eu uso de vez em quando quando necessário"],
        question:
          "como você descreveria o seu uso e interação com o nosso produto/serviço?"
      },
      {
        name: "Uso raro",
        options: [
          "Uso raro - Eu raramente uso, apenas em situações específicas"
        ],
        question:
          "como você descreveria o seu uso e interação com o nosso produto/serviço?"
      },
      {
        name: "Não uso atualmente",
        options: [
          "Não uso atualmente - Eu comprei ou assinei, mas não estou usando ativamente"
        ],
        question:
          "como você descreveria o seu uso e interação com o nosso produto/serviço?"
      }
    ]
  },
  {
    title: "Elasticidade",
    items: [
      {
        name: "Muito sensível",
        options: [
          "Mesmo uma pequena mudança de preço me faria reconsiderar minha decisão de compra"
        ],
        question:
          "como uma mudança de preços afetaria a sua decisão de continuar"
      },
      {
        name: "Moderadamente sensível",
        options: [
          "Mudanças moderadas de preço poderiam influenciar minha decisão de compra"
        ],
        question:
          "como uma mudança de preços afetaria a sua decisão de continuar"
      },
      {
        name: "Pouco sensível",
        options: [
          "Somente mudanças significativas de preço afetariam minha decisão de compra"
        ],
        question:
          "como uma mudança de preços afetaria a sua decisão de continuar"
      },
      {
        name: "Nada sensível",
        options: [
          "Mudanças de preço não têm impacto na minha decisão de compra; outros fatores são mais importantes para mim"
        ],
        question:
          "como uma mudança de preços afetaria a sua decisão de continuar"
      }
    ]
  },
  {
    title: "Inovação",
    items: [
      {
        name: "Inovação - Sim",
        options: ["Sim"],
        question: "E você acredita que a nossa marca é inovadora?"
      },
      {
        name: "Inovação - Não",
        options: ["Não"],
        question: "E você acredita que a nossa marca é inovadora?"
      },
      {
        name: "Inovação - Não sei avaliar",
        options: ["Não sei avaliar"],
        question: "E você acredita que a nossa marca é inovadora?"
      }
    ]
  },
  {
    title: "Employee Branding",
    items: [
      {
        name: "EB - Ficaria orgulhoso",
        options: ["Sim, eu ficaria orgulhoso"],
        question:
          "E, se você fosse trabalhar na nossa marca, você ficaria orgulhoso de trabalhar em fazer parte da nossa empresa?"
      },
      {
        name: "EB - Ficaria envergonhado",
        options: ["Não, eu ficaria envergonhado"],
        question:
          "E, se você fosse trabalhar na nossa marca, você ficaria orgulhoso de trabalhar em fazer parte da nossa empresa?"
      },
      {
        name: "EB - Não sei avaliar",
        options: ["Não sei avaliar"],
        question:
          "E, se você fosse trabalhar na nossa marca, você ficaria orgulhoso de trabalhar em fazer parte da nossa empresa?"
      }
    ]
  },
  {
    title: "Análise Psicográfica",
    items: [
      {
        name: "Conveniência",
        options: [
          "Conveniência: Eu valorizo a facilidade de uso e acessibilidade"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Qualidade",
        options: [
          "Qualidade: Eu busco os melhores produtos/serviços disponíveis"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Preço",
        options: ["Preço: Eu sou motivado principalmente pelo custo-benefício"],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Status",
        options: [
          "Status: Eu gosto de produtos/serviços que elevam meu status social"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Sustentabilidade",
        options: [
          "Sustentabilidade: Eu prefiro produtos/serviços ambientalmente responsáveis"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Inovação",
        options: [
          "Inovação: Eu sou atraído por novidades e tecnologia de ponta"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Personalização",
        options: [
          "Personalização: Eu valorizo produtos/serviços que podem ser personalizados para minhas necessidades"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      },
      {
        name: "Comunidade",
        options: [
          "Comunidade: Eu escolho marcas que promovem um senso de comunidade ou apoiam causas sociais"
        ],
        question: "qual a razão principal que você escolheu a nossa marca?"
      }
    ]
  }
];

export default optionsCrossing;
