import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import InsightsChat from "../InsightsChat";
import InsightsService from "../../services/insightsService";
import Plus from "../../assets/img/plus.png";
import InsightsReport from "../InsightsReport";
import { userContext } from "../../context/User";

const Insights = () => {
  const [listMonth, setListMonth] = useState([]);
  const [listChat, setListChat] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeRender, setTypeRender] = useState("report");
  const [monthSelected, setMonthSelected] = useState(null);
  const [chatSelected, setChatSelected] = useState(null);
  const { project } = useContext(userContext);

  useEffect(() => {
    if (loading) {
      handleGetListMonth();
      handleGetListChat();
    }
    // eslint-disable-next-line
  }, []);

  const handleGetListMonth = async () => {
    let response = await InsightsService.getListMonthData({
      project: project
    });

    if (Array.isArray(response.data)) {
      setListMonth(
        response.data.map((element) => {
          return element.list_month;
        })
      );
    }
  };

  const handleGetListChat = async () => {
    let response = await InsightsService.getListChatsData({
      project: project
    });

    if (Array.isArray(response.data)) {
      setListChat(
        response.data.map((element) => {
          return element.id_chat;
        })
      );
    }
    setLoading(false);
  };

  const handleMonthSelected = async (monthName) => {
    setTypeRender("report");
    setChatSelected(null);
    setMonthSelected(monthName);
  };

  const handleChatSelected = async (chat) => {
    setTypeRender("chat");
    setMonthSelected(null);
    setChatSelected(chat);
  };

  const handleNewChat = async () => {
    const response = await InsightsService.newChat({
      project: project
    });

    if (response?.data) {
      setChatSelected(response.data);
    }

    await handleGetListChat();
  };

  if (loading) {
    return <div>loading</div>;
  } else {
    return (
      <div className="compPromp">
        <div className="asidePrompt">
          <button
            onClick={() => {
              handleNewChat();
            }}
          >
            New chat
            <img className="plus" src={Plus} alt="plus" />
          </button>
          <div>
            <span>Previous chat</span>
            <ul>
              {listChat
                .map((element, index) => {
                  return (
                    <li
                      className={chatSelected === element ? "liSelected" : ""}
                      key={`${element}_${index}`}
                      onClick={() => handleChatSelected(element)}
                    >
                      Chat {index + 1}
                    </li>
                  );
                })
                .reverse()}
            </ul>
          </div>
          <hr />
          <div>
            <span>Monthly report</span>
            <ul>
              {listMonth
                .map((element, index) => {
                  return (
                    <li
                      className={monthSelected === element ? "liSelected" : ""}
                      key={`${element}_${index}`}
                      onClick={() => handleMonthSelected(element)}
                    >
                      {element}
                    </li>
                  );
                })
                .reverse()}
            </ul>
          </div>
        </div>
        <div className="contentPrompt">
          {typeRender === "report" && (
            <InsightsReport monthSelected={monthSelected} />
          )}
          {typeRender === "chat" && (
            <InsightsChat chatSelected={chatSelected} />
          )}
        </div>
      </div>
    );
  }
};

export default Insights;
