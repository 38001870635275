import MetricsService from "../../services/metricsService";
import randomColor from "randomcolor";

const handleDatasetSimpleFrequencies = async (response, datasetList) => {
  if (response?.data?.response === "Not exists Data") {
    return null;
  }

  let datasets = [];

  let datasetPositive = Object.keys(response).map((key) => {
    let values = [];
    response[key].forEach((element) => {
      if (datasetList.question?.answer_positive.indexOf(element.answer) >= 0) {
        if (values.length > 0) {
          values[0] += parseInt(element.percent);
        } else {
          values.push(parseInt(element.percent));
        }
      }
    });
    return values;
  });
  datasets.push({
    label: datasetList.question?.alias_positive
      ? `${datasetList.question?.alias_positive} %`
      : "Positivo %",
    backgroundColor: `${datasetList.color}99`,
    borderColor: datasetList.color,
    borderWidth: 1,
    hoverBackgroundColor: datasetList.color,
    hoverBorderColor: datasetList.color,
    data: datasetPositive[0]
  });

  let datasetNeutral = Object.keys(response).map((key) => {
    let values = [];
    response[key].forEach((element) => {
      if (datasetList.question.answer_neutral?.indexOf(element.answer) >= 0) {
        if (values.length > 0) {
          values[0] += parseInt(element.percent);
        } else {
          values.push(parseInt(element.percent));
        }
      }
    });
    return values;
  });
  if (datasetNeutral[0].length > 0) {
    datasets.push({
      label: datasetList.question?.alias_neutral
        ? `${datasetList.question?.alias_neutral} %`
        : "Neutro %",
      backgroundColor: `#84848499`,
      borderColor: "#848484",
      borderWidth: 1,
      hoverBackgroundColor: "#848484",
      hoverBorderColor: "#848484",
      data: datasetNeutral[0]
    });
  }

  let datasetNegative = Object.keys(response).map((key) => {
    let values = [];
    response[key].forEach((element) => {
      if (datasetList.question?.answer_negative.indexOf(element.answer) >= 0) {
        if (values.length > 0) {
          values[0] += parseInt(element.percent);
        } else {
          values.push(parseInt(element.percent));
        }
      }
    });
    return values;
  });
  datasets.push({
    label: datasetList.question?.alias_negative
      ? `${datasetList.question?.alias_negative} %`
      : "Negativo %",
    backgroundColor: `#e3263699`,
    borderColor: "#e32636",
    borderWidth: 1,
    hoverBackgroundColor: "#e32636",
    hoverBorderColor: "#e32636",
    data: datasetNegative[0]
  });

  return datasets;
};

/*****************************************************************************
 * Request Simple Data With Classification
 * ***************************************************************************/
const requestSimpleDataWithClassification = async (
  obj,
  project,
  filter = "max"
) => {
  let response = await MetricsService.getSimpleFrequency({
    filter: filter,
    project: project,
    question_text: obj.question.question_query
  });

  let datasets = await handleDatasetSimpleFrequencies(response, obj);

  if (datasets) {
    return {
      data: {
        labels: Object.keys(response).map((key) => {
          return key;
        }),
        datasets: obj.title !== "Market Fit" ? datasets : datasets.reverse()
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * Request Simple Data Without Classification
 * ***************************************************************************/
const requestSimpleDataWithoutClassification = async (
  obj,
  project,
  filter = "max"
) => {
  let response = await MetricsService.getSimpleFrequency({
    filter: filter,
    project: project,
    question_text: obj.question.question_query
  });

  if (response?.data?.response === "Not exists Data") {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }

  if (!Array.isArray(response.data)) {
    const matrixOptions = [];
    Object.keys(response).forEach((key) => {
      response[key].forEach((option) => {
        if (matrixOptions.indexOf(option.answer) === -1) {
          matrixOptions[option.answer] = [];
        }
      });
    });

    Object.keys(response).forEach((key) => {
      Object.keys(matrixOptions).forEach((matrixOption) => {
        const search = response[key].filter(
          (elementFilter) => elementFilter.answer === matrixOption
        );

        if (search.length > 0) {
          matrixOptions[matrixOption].push(search[0].percent);
        } else {
          matrixOptions[matrixOption].push(0);
        }
      });
    });

    return {
      data: {
        labels: Object.keys(response).map((key) => {
          return key;
        }),
        datasets: Object.keys(matrixOptions).map((matrixOption) => {
          const color = randomColor();

          return {
            label: handleElasticityData(matrixOption),
            backgroundColor: `${color}99`,
            borderColor: color,
            borderWidth: 1,
            hoverBackgroundColor: color,
            hoverBorderColor: color,
            data: matrixOptions[matrixOption]
          };
        })
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

const handleElasticityData = (title) => {
  switch (title) {
    case "Mesmo uma pequena mudança de preço me faria reconsiderar minha decisão de compra":
      return "Muito sensível";
    case "Mudanças moderadas de preço poderiam influenciar minha decisão de compra":
      return "Moderadamente sensível";
    case "Somente mudanças significativas de preço afetariam minha decisão de compra":
      return "Pouco sensível";
    case "Mudanças de preço não têm impacto na minha decisão de compra; outros fatores são mais importantes para mim":
      return "Nada sensível";
    case "Uso frequente e intensivo - Ele é uma parte essencial do meu dia a dia":
      return "Uso frequente e intensivo";
    case "Uso regular - Eu uso consistentemente, mas não é central para as minhas atividades diárias":
      return "Uso regular";
    case "Uso ocasional - Eu uso de vez em quando quando necessário":
      return "Uso ocasional";
    case "Uso raro - Eu raramente uso, apenas em situações específicas":
      return "Uso raro";
    case "Não uso atualmente - Eu comprei ou assinei, mas não estou usando ativamente":
      return "Não uso atualmente";
    default:
      return title;
  }
};

/*****************************************************************************
 * NPS Calculated
 * ***************************************************************************/
const requestAndSetNpsCalculatedData = async (_, project, filter = "max") => {
  let response = await MetricsService.getSimpleFrequency({
    filter: filter,
    project: project,
    question_text:
      "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?"
  });

  if (response) {
    let dataGeneral = {};
    let dataNpsCalculate = {};

    Object.entries(response).forEach(([key, value]) => {
      let promoter = 0;
      let promoterQtd = 0;
      let neutral = 0;
      let neutralQtd = 0;
      let detrator = 0;
      let detratorQtd = 0;
      for (let i = 0; i < value.length; i++) {
        if (value[i].answer > 8) {
          promoter += parseFloat(value[i].percent);
          promoterQtd += parseFloat(value[i].qtd);
        } else if (value[i].answer > 6) {
          neutral += parseFloat(value[i].percent);
          neutralQtd += parseFloat(value[i].qtd);
        } else if (value[i].answer > -1) {
          detrator += parseFloat(value[i].percent);
          detratorQtd += parseFloat(value[i].qtd);
        }
        dataGeneral[key] = [
          { answer: "Promotores", qtd: promoterQtd, percent: promoter },
          { answer: "Neutros", qtd: neutralQtd, percent: neutral },
          { answer: "Detratores", qtd: detratorQtd, percent: detrator }
        ];
        dataNpsCalculate[key] = {
          answer: "NPS",
          qtd: promoterQtd,
          percent: promoter - detrator
        };
      }
    });

    return {
      data: {
        labels: Object.entries(dataNpsCalculate).map(([key, value]) => {
          return value.answer;
        }),
        datasets: [
          {
            label: "Net Promoter Score %",
            backgroundColor: "rgba(248, 108, 105, 0.6)",
            borderColor: "rgb(248, 108, 105)",
            borderWidth: 1,
            hoverBackgroundColor: "#f86c69",
            hoverBorderColor: "#f86c69",
            data: Object.entries(dataNpsCalculate).map(([key, value]) => {
              return value.percent;
            })
          }
        ]
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * NPS Simple Frequency
 * ***************************************************************************/
const requestAndSetNpsSimpleFrequencyData = async (
  _,
  project,
  filter = "max"
) => {
  let response = await MetricsService.getSimpleFrequency({
    filter: filter,
    project: project,
    question_text:
      "Em uma escala de 0 a 10 o quanto você recomendaria o seu plano para um amigo ou familiar?"
  });

  if (response) {
    let dataGeneral = {};
    let dataNpsCalculate = {};

    Object.entries(response).forEach(([key, value]) => {
      let promoter = 0;
      let promoterQtd = 0;
      let neutral = 0;
      let neutralQtd = 0;
      let detrator = 0;
      let detratorQtd = 0;
      for (let i = 0; i < value.length; i++) {
        if (value[i].answer > 8) {
          promoter += parseFloat(value[i].percent);
          promoterQtd += parseFloat(value[i].qtd);
        } else if (value[i].answer > 6) {
          neutral += parseFloat(value[i].percent);
          neutralQtd += parseFloat(value[i].qtd);
        } else if (value[i].answer > -1) {
          detrator += parseFloat(value[i].percent);
          detratorQtd += parseFloat(value[i].qtd);
        }
        dataGeneral[key] = [
          { answer: "Promotores", qtd: promoterQtd, percent: promoter },
          { answer: "Neutros", qtd: neutralQtd, percent: neutral },
          { answer: "Detratores", qtd: detratorQtd, percent: detrator }
        ];
        dataNpsCalculate[key] = {
          answer: "NPS",
          qtd: promoterQtd,
          percent: promoter / detrator
        };
      }
    });

    return {
      data: {
        labels: Object.entries(dataGeneral).map(([key, value]) => {
          return key;
        }),
        datasets: [
          {
            label: "Detratores %",
            backgroundColor: "rgba(184, 29, 19, 0.6)",
            borderColor: "rgb(184, 29, 19)",
            borderWidth: 1,
            hoverBackgroundColor: "rgb(184, 29, 19)",
            hoverBorderColor: "rgb(184, 29, 19)",
            data: Object.entries(dataGeneral).map(([key, value]) => {
              return value
                .filter((element) => {
                  return element.answer === "Detratores"
                    ? element.percent
                    : null;
                })
                .map((element) => {
                  return element.percent;
                });
            })
          },
          {
            label: "Neutros %",
            backgroundColor: "rgba(239, 183, 0, 0.6)",
            borderColor: "rgb(239, 183, 0)",
            borderWidth: 1,
            hoverBackgroundColor: "rgb(239, 183, 0)",
            hoverBorderColor: "rgb(239, 183, 0)",
            data: Object.entries(dataGeneral).map(([key, value]) => {
              return value
                .filter((element) => {
                  return element.answer === "Neutros" ? element.percent : null;
                })
                .map((element) => {
                  return element.percent;
                });
            })
          },
          {
            label: "Promotores %",
            backgroundColor: "rgba(0, 132, 80, 0.6)",
            borderColor: "rgb(0, 132, 80)",
            borderWidth: 1,
            hoverBackgroundColor: "rgb(0, 132, 80)",
            hoverBorderColor: "rgb(0, 132, 80)",
            data: Object.entries(dataGeneral).map(([key, value]) => {
              return value
                .filter((element) => {
                  return element.answer === "Promotores"
                    ? element.percent
                    : null;
                })
                .map((element) => {
                  return element.percent;
                });
            })
          }
        ]
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * NPS Crossing
 * ***************************************************************************/
const requestAndSetNpsCrossingData = async (_, project, filter = "max") => {
  let response = await MetricsService.getSimpleFrequencyNps({
    filter: filter,
    project: project
  });

  if (Array.isArray(response.data)) {
    return {
      data: {
        labels: response.data.map((responseElement) => {
          return responseElement.value;
        }),
        datasets: [
          {
            label: "Net Promoter Score %",
            backgroundColor: "rgba(248, 108, 105, 0.6)",
            borderColor: "rgb(248, 108, 105)",
            borderWidth: 1,
            hoverBackgroundColor: "#f86c69",
            hoverBorderColor: "#f86c69",
            data: response.data.map((responseElement) => {
              return responseElement.nps;
            })
          },
          {
            label: "Buyscore %",
            backgroundColor: "rgba(93, 85, 250, 0.6)",
            borderColor: "rgb(93, 85, 250)",
            borderWidth: 1,
            hoverBackgroundColor: "#603fef",
            hoverBorderColor: "#603fef",
            data: response.data.map((responseElement) => {
              return responseElement.buyscore;
            })
          },
          {
            label: "Indicaria %",
            backgroundColor: "rgba(198, 48, 210, 0.6)",
            borderColor: "rgb(198, 48, 210)",
            borderWidth: 1,
            hoverBackgroundColor: "#c630d2",
            hoverBorderColor: "#c630d2",
            data: response.data.map((responseElement) => {
              return responseElement.indication;
            })
          }
        ]
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * Simple Frequency Psychographic
 * ***************************************************************************/
const requestSimpleFrequencyPsy = async (_, project, filter = "max") => {
  let response = await MetricsService.getSimpleFrequencyPsy({
    filter: filter,
    project: project
  });

  if (Array.isArray(response.data)) {
    return {
      data: {
        labels: response.data.map((responseElement) => {
          return responseElement.answer.split(":")[0];
        }),
        datasets: [
          {
            label: "Análise Psicográfica %",
            backgroundColor: "#5d55fa99",
            borderColor: "#5d55fa",
            borderWidth: 1,
            hoverBackgroundColor: "#5d55fa",
            hoverBorderColor: "#5d55fa",
            data: response.data.map((responseElement) => {
              return responseElement.percent;
            })
          }
        ]
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * Simple Frequency Brand Relationship
 * ***************************************************************************/
const requestSimpleFrequencyBrandRelationship = async (
  obj,
  project,
  filter = "max"
) => {
  let response = await MetricsService.getSimpleFrequencyBrand({
    filter: filter,
    project: project
  });

  if (Array.isArray(response)) {
    return {
      data: {
        labels: response.map((responseElement) => {
          return responseElement.month;
        }),
        datasets: [
          {
            label: "Relacionamento de Marca %",
            backgroundColor: "#5d55fa99",
            borderColor: "#5d55fa",
            borderWidth: 1,
            hoverBackgroundColor: "#5d55fa",
            hoverBorderColor: "#5d55fa",
            data: response.map((responseElement) => {
              return responseElement.percent;
            })
          }
        ]
      }
    };
  } else {
    return {
      data: {
        labels: [],
        datasets: []
      }
    };
  }
};

/*****************************************************************************
 * Exports
 * ***************************************************************************/
export {
  requestSimpleDataWithClassification,
  requestSimpleDataWithoutClassification,
  requestSimpleFrequencyBrandRelationship,
  requestSimpleFrequencyPsy,
  requestAndSetNpsCalculatedData,
  requestAndSetNpsSimpleFrequencyData,
  requestAndSetNpsCrossingData
};
