import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import ForgotPassword from "../pages/ForgotPassword";
import SignIn from "../pages/SignIn";
import NotFound from "../pages/NotFound";
import RouteWrapper from "./RouteWrapper";

export default function AllRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RouteWrapper />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      <Route path="/login" element={<SignIn />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  );
}
