import { useContext, useEffect, useState } from "react";
import OptionsCrossing from "./constants";
import { Scatter } from "react-chartjs-2";
import randomColor from "randomcolor";
import "./style.scss";
import MetricsService from "../../../services/metricsService";
import { userContext } from "../../../context/User";

function Crossing() {
  const [filterCrossing, setFilterCrossing] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { project } = useContext(userContext);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 40,
        left: 40,
        right: 40,
        top: 40
      }
    },
    plugins: {
      datalabels: {
        color: "#fff",
        align: "end",
        display: true,
        formatter: function (value, context) {
          return `[BuyScore]: ${value.x}\n[${context.dataset.label}]: ${value.y}`;
        }
      },
      legend: {
        position: "bottom",
        labels: {
          color: "#fff",
          padding: 20,
          usePointStyle: true
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 110,
        min: 0,
        stepSize: 10,
        ticks: {
          beginAtZero: true,
          callback: (value) => (value > 100 ? "" : value),
          color: "#ffffff88",
          fontSize: 20
        },
        grid: {
          color: function (context) {
            if (context.tick.value === 0) {
              return "#ffffff";
            } else if (context.tick.value === 70) {
              return "#5d55fa";
            }

            return "#ffffff1A";
          },
          lineWidth: function (context) {
            if (context.tick.value === 0) {
              return 2;
            } else if (context.tick.value === 70) {
              return 2;
            }

            return 1;
          }
        },
        title: {
          display: true,
          text: "Cohorts",
          color: "#fff",
          font: {
            size: 16,
            style: "normal",
            weight: "bold",
            lineHeight: 1.2
          }
        }
      },
      x: {
        beginAtZero: true,
        max: 110,
        min: 0,
        stepSize: 10,
        ticks: {
          beginAtZero: true,
          callback: (value) => (value > 100 ? "" : value),
          color: "#ffffff88",
          fontSize: 12
        },
        grid: {
          color: function (context) {
            if (context.tick.value === 0) {
              return "#ffffff";
            } else if (context.tick.value === 70) {
              return "#5d55fa";
            }

            return "#ffffff1A";
          },
          lineWidth: function (context) {
            if (context.tick.value === 0) {
              return 2;
            } else if (context.tick.value === 70) {
              return 2;
            }

            return 1;
          }
        },
        title: {
          display: true,
          text: "Buyscore",
          color: "#fff",
          font: {
            size: 16,
            style: "normal",
            weight: "bold",
            lineHeight: 1.2
          }
        }
      }
    }
  };

  const handleFilterCrossing = async (checked, option) => {
    if (checked) {
      let response = null;

      if (option.name.substring(0, 3) !== "NPS") {
        response = await MetricsService.getCrossingData({
          answer: option.options,
          project: project,
          question_text: option.question,
          user: "marcus.trindade@121labs.io"
        });
      } else if (option.name.substring(0, 3) === "NPS") {
        response = await MetricsService.getCrossingDataNps({
          answer: option.options,
          project: project,
          question_text: option.question,
          user: "marcus.trindade@121labs.io"
        });
      }

      if (response.data) {
        setFilterCrossing((prev) => {
          return [...prev, option.name];
        });
        setData((prev) => {
          return {
            datasets: [
              ...prev.datasets,
              {
                backgroundColor: randomColor(),
                data: [
                  {
                    x: response.data.percentBuyscore,
                    y: response.data.percentCohort
                  }
                ],
                label: option.name,
                pointRadius: 10
              }
            ]
          };
        });
      }
    } else {
      setFilterCrossing(
        filterCrossing.filter((element) => {
          return element !== option.name ? element : null;
        })
      );
      setData({
        datasets: data.datasets.filter((element) => {
          return element.label !== option.name;
        })
      });
    }
  };

  useEffect(() => {
    const getCrossingDefault = async () => {
      const response = await MetricsService.getCrossingDataDefault({
        project: project,
        user: "marcus.trindade@121labs.io"
      });

      if (response.data.percent) {
        setData({
          datasets: [
            {
              backgroundColor: "#5d55fa",
              data: [{ x: response.data.percent, y: response.data.percent }],
              label: "BuyScore Geral",
              pointRadius: 10
            }
          ]
        });
        setLoading(false);
      }
    };

    if (data === null) {
      getCrossingDefault();
    }
    // eslint-disable-next-line
  }, []);

  const selectAllOptions = (sectionSelected) => {
    let arrayToRequest = [];
    OptionsCrossing.forEach((section) => {
      if (sectionSelected === section.title) {
        section.items.forEach((items) => {
          arrayToRequest.push(items);
        });
      }
    });

    arrayToRequest.forEach((item) => {
      if (filterCrossing.indexOf(item.name) === -1) {
        handleFilterCrossing(true, item);
      }
    });
  };

  const deselectAllOptions = (sectionSelected) => {
    OptionsCrossing.forEach((section) => {
      if (sectionSelected === section.title) {
        let arrayToCompare = section.items.map((element) => {
          return element.name;
        });

        let filterCrossingTemp = filterCrossing.filter((element) => {
          return arrayToCompare.indexOf(element) === -1 ? element : null;
        });
        setFilterCrossing(filterCrossingTemp);

        let dataTemp = data.datasets.filter((element) => {
          return arrayToCompare.indexOf(element.label) === -1 ? element : null;
        });
        setData({ datasets: dataTemp });
      }
    });
  };

  if (loading) {
    return <div>loading</div>;
  } else {
    return (
      <div className="compCrossing">
        <div className="chartBox">
          <Scatter options={options} height="350" width="800" data={data} />
        </div>
        <div className="filterAreaCrossing">
          <div className="descriptionFilterArea">
            <p className="titleFilter">Cohorts</p>
            <p className="descriptionFilter">
              Selecione os cohorts para avaliar o resultado do BuyScore
            </p>
          </div>
          <ul className="firstListCrossing">
            {OptionsCrossing.map((section, index) => {
              return (
                <ul key={`title_${index}`}>
                  <li className="titleCrossing">{section.title}</li>
                  <div className="selectArea">
                    <u
                      className="selectOptions"
                      onClick={() => selectAllOptions(section.title)}
                    >
                      Selecionar tudo
                    </u>
                    <u
                      className="selectOptions"
                      onClick={() => deselectAllOptions(section.title)}
                    >
                      Limpar
                    </u>
                  </div>
                  {section.items.map((items) => {
                    return (
                      <li
                        className="itemCrossing"
                        key={`${index}_${items.name}`}
                      >
                        <input
                          type="checkbox"
                          name={items.name}
                          id={items.name}
                          onChange={(event) =>
                            handleFilterCrossing(event.target.checked, items)
                          }
                          checked={
                            filterCrossing.indexOf(items.name) > -1
                              ? true
                              : false
                          }
                        />
                        {items.name}
                      </li>
                    );
                  })}
                </ul>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default Crossing;
