import { firebaseAuth } from "../config/firebase";
import Axios from "axios";
export const Auth = firebaseAuth;

export default class insightsService {
  static getListMonthData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}/v1/analytics/insights/list_process/?user=${email}&campaign=${object.project}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getMonthData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}/v1/analytics/insights/?user=${email}&campaign=${object.project}&month=${object.month}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static newChat = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/prompt/chat/`,
      data: JSON.stringify({
        campaign: object.project,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getListChatsData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/prompt/chats/?user=${email}&campaign=${object.project}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static getChatsData = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/prompt/messages_chat/?campaign=${object.project}&id_chat=${object.id_chat}&user=${email}`
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };

  static promptConversation = async (object) => {
    let response = null;
    let email = firebaseAuth.currentUser.email;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL_API_HAZO_AI}v1/analytics/prompt/`,
      data: JSON.stringify({
        campaign: object.project,
        id_chat: object.id_chat,
        text_question: object.text_question,
        user: email
      })
    })
      .then((data) => {
        response = data.data;
      })
      .catch(() => {
        response = false;
      });

    return response;
  };
}
