import { useContext, useEffect, useState, useRef } from "react";
import { userContext } from "../../context/User";
import Hazo from "../../assets/img/hazo_ai_croped.png";
import InsightsService from "../../services/insightsService";
import TypingText from "../typingText";
import "./style.scss";

function InsightsReport(props) {
  const { monthSelected } = props;
  const [textData, setTextDate] = useState("");
  const { project } = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      let scrollPosition =
        document.querySelector(".comInsightsReport").scrollTop;
      let pageHeight =
        document.querySelector(".comInsightsReport").clientHeight;
      let scrollHeight =
        document.querySelector(".comInsightsReport").scrollHeight;
      let scrollHeightMinPosition = scrollHeight * 0.9;

      if (scrollPosition + pageHeight > scrollHeightMinPosition) {
        messagesEndRef.current.scrollIntoView({
          behavior: "instant",
          block: "end",
          inline: "nearest"
        });
      }
    }
  };

  useEffect(() => {
    const handleData = async () => {
      let response = await InsightsService.getMonthData({
        project: project,
        month: monthSelected
      });

      if (Array.isArray(response.data)) {
        if (response.data[0]?.text_report) {
          setTextDate(response.data[0]?.text_report);
        } else {
          setTextDate("");
        }
        setLoading(false);
      }
    };

    handleData();
    // eslint-disable-next-line
  }, [monthSelected]);

  if (loading) {
    return "Loading";
  } else {
    return (
      <div className="comInsightsReport">
        {textData && (
          <div className="messageArea">
            <img src={Hazo} alt="hazo" className="iconHazo" />
            <div className="message">
              <span>IA:</span>
              <TypingText
                speed={5}
                scrollToBottom={scrollToBottom}
                text={textData}
              />
            </div>
          </div>
        )}
        <div ref={messagesEndRef}></div>
      </div>
    );
  }
}
export default InsightsReport;
