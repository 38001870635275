import React from "react";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import EyeVisible from "../../assets/img/ICONEOLHOHAZO.png";
import EyeInvisible from "../../assets/img/ICONEOLHOHAZOFECHADO.png";
import HazoAi from "../../assets/img/hazo_ai.png";
import HazoLettering from "../../assets/img/Picture5.png";
import Loading from "../../components/Loading";

const SignIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  //eslint-disable-next-line
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  async function handleSubmit() {
    setErrorStatus(null);
    const isValidEmail = new RegExp(
      // eslint-disable-next-line
      /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    ).test(email);

    if (email.length === 0 || password.length === 0) {
      return setErrorStatus("Por favor, insira o email e a senha");
    } else if (email.length !== 0 && !isValidEmail) {
      return setErrorStatus("Email inválido");
    }

    let authResult;
    try {
      authResult = await authService.signIn({ email, password });
    } catch (error) {
      return setErrorStatus("Ocorreu um erro durante o login.");
    }
    const { response, errorMessage } = authResult || {};
    if (errorMessage) {
      if (errorMessage.match(/auth\/user-disabled/gi)) {
        setErrorStatus("Usuário não encontrado");
      } else if (
        errorMessage.match(/auth\/wrong-password/gi) ||
        errorMessage.match(/auth\/invalid-login-credentials/gi)
      ) {
        setErrorStatus("Usuário ou senha incorreto.");
      } else if (errorMessage.match(/auth\/too-many-requests/gi)) {
        setErrorStatus(
          "Voce fez muitas tentativas com a senha incorreta. Por favor, clique em 'Esqueceu a senha' para restaurar sua senha."
        );
      }
    } else if (response) {
      navigate("/dashboard");
      setLoading(false);
    }
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <section className="compSignIn">
        <div className="container">
          <div className="imagesArea">
            <img alt="Hazo avatar" className="imgHazoAi" src={HazoAi} />
            <img
              alt="Hazo AI Lettering"
              className="lettering"
              src={HazoLettering}
            />
            <h1>Metrics & Insights</h1>
          </div>
          <div className="inputArea">
            <input
              autoComplete="on"
              type="email"
              id="typeEmailX"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDownCapture={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </div>
          <div className="inputArea">
            <input
              autoComplete="on"
              type={passwordVisible ? "text" : "password"}
              id="typePasswordX"
              placeholder="senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDownCapture={(event) => {
                if (event.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            {passwordVisible ? (
              <img
                alt="eye opend"
                className="iconEye"
                onClick={() => setPasswordVisible(false)}
                src={EyeVisible}
              />
            ) : (
              <img
                alt="eye closed"
                className="iconEye"
                onClick={() => setPasswordVisible(true)}
                src={EyeInvisible}
              />
            )}
          </div>
          <button onClick={handleSubmit}>Entrar</button>
          {errorStatus && <p>{errorStatus}</p>}
          <Link to="/forgotpassword">Esqueci minha senha</Link>
        </div>
      </section>
    );
  }
};

export default SignIn;
