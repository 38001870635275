// BarChart.js

import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import "../style.scss";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import FilterSelection from "../../FilterSelection";

Chart.register(ChartDataLabels);

const BarChart = (chartData) => {
  const [filter, setFilter] = useState("MAX");
  const {
    action,
    data,
    description,
    grid,
    legend,
    min,
    stack,
    question,
    title,
    top
  } = chartData;

  const options = {
    indexAxis: "x",
    maxBarThickness: 100,
    layout: {
      padding: {
        top: 10
      }
    },
    plugins: {
      datalabels: {
        align: "center",
        color: "white",
        display: true
      },
      legend: {
        labels: {
          color: "#fff"
        },
        display: legend ? true : false
      }
    },
    scales: {
      y: {
        grid: {
          color: function (context) {
            if (context.tick?.value === 0 && grid) {
              return "#ffffff1A";
            } else {
              return "#0000001A";
            }
          }
        },
        min: min ? min : 0,
        max: top ? top : 100,
        stepSize: 5,
        stacked: stack,
        ticks: {
          beginAtZero: true,
          color: "white",
          fontSize: 12
        }
      },
      x: {
        stacked: stack,
        ticks: {
          beginAtZero: true,
          color: "white",
          fontSize: 12
        }
      }
    }
  };

  return (
    <div className="chart">
      {title ? <h3>{title}</h3> : null}
      <FilterSelection
        action={action}
        filter={filter}
        question={question}
        setFilter={setFilter}
      />
      <div className="chartBox">
        {data.data === null ? (
          <Bar
            data={{ datasets: [], labels: [] }}
            height="350"
            width="800"
            options={options}
          />
        ) : (
          <Bar data={data.data} height="350" width="800" options={options} />
        )}
      </div>
      {description}
    </div>
  );
};

export default BarChart;
