import React from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import authService from "../../services/authService";
import HazoAi from "../../assets/img/hazo_ai.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");

  return (
    <section className="compForgotPassword">
      <div className="container">
        <img className="imgHazoAi" src={HazoAi} alt="Hazo ai" />
        <p>
          Por favor, informe seu email e para receber um email de redefinição!
        </p>
        <div className="inputArea">
          <input
            autoComplete="on"
            type="email"
            id="typeEmailX"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDownCapture={(event) => {
              if (event.key === "Enter") {
                authService.sendEmailToResetPassword(email);
              }
            }}
          />
        </div>
        <div className="buttonsArea">
          <button onClick={() => navigate("/login")} className="secondary">
            Voltar
          </button>
          <button
            onClick={async () => {
              await authService.sendEmailToResetPassword(email);
              navigate("/login");
            }}
            className="primary"
          >
            Enviar
          </button>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
